import React, { useEffect, useContext, useState } from "react"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import clsx from "clsx"
import Select from "@ecom/ui/components/Select"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import {
  pushToDataLayerInvalidField,
  pushToDataLayerLoadedStep,
  pushToDataLayerSendForm,
  pushToDataLayerStepSuccess,
} from "@ecom/ui/utils/pushToDataLayerUtils"

import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import FormHelperText from "@material-ui/core/FormHelperText"
import Button from "@material-ui/core/Button"
import MUITexteField from "@material-ui/core/TextField"
import { PhoneField } from "./fields/PhoneField"
import { FioField } from "./fields/FioField"
import { BirthField } from "./fields/BirthField"
import { CityField } from "./fields/CityField"
import { AcceptmentField } from "./fields/AcceptmentField"
import { PickupForForm } from "../../PickupOfficeForForm"

import { setIDBValue } from "../../../utils/idbUtils"

import { sendRequest } from "../../../helpers/sendRequest"
import { formatBirthDate } from "../../../helpers/formHelpers"
import { events } from "../../../helpers/events"
import { CalcWithFormContext } from "../../../context"

import { FormSchema } from "./FormSchema"

import * as styles from "./personalForm.module.scss"
import { ChoiceProduct } from "./fields/ChoiceProduct"
import {
  FormValues,
  IPersonalForm,
  THANKS_PAGE,
  Response,
  OfficeDataForSelect,
  OfficeDataWithMoreInfo,
  IDataCity,
} from "../types"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"
import ProgressBarGreen from "../../ProgressBarGreen"
import { ValidationPoints } from "../../ProgressBar/types"
import { itemsSelect } from "../helper"
import fetchOffices from "../../PickupOfficeForForm/helpers/fetchOffices"

const DEFAULT_CITY_MOSCOW = {
  city_kladr_id: "7700000000000",
  kladr_id: "7700000000000",
  city: "Москва",
  city_with_type: "г Москва",
  geo_lat: "55.75396",
  geo_lon: "37.620393",
}

export const PersonalForm = ({
  frame = false,
  dataLayerName = "shortPersonalForm",
  hasBirth = false,
  hasCityWithOffices = false,
  onSubmit,
  formBtnText = "Получить карту",
  noVerify = false,
  longForm = false,
  productName = "КК Халва",
  inputsColor = "white",
  fullWidth,
  variant = "standart",
  isGreenApprove,
  fioPlaceHolder = "ФИО",
  choiceProduct,
  setProductName,
  hasSelect = false,
  thanksPage = THANKS_PAGE.SUCCESS,
  progressBar,
  selectItems = itemsSelect,
  selectTitle = "Как вы узнали про акцию?",
}: IPersonalForm) => {
  const { loanAmount, term } = useContext(CalcWithFormContext)
  const [showCustomInput, setShowCustomInput] = useState(false)
  const [focusPhone, setFocusPhone] = useState(false)
  const [officesFromRequest, setOfficesFromRequest] = useState<OfficeDataWithMoreInfo[]>([])
  const [officesForSelect, setOfficesForSelect] = useState<OfficeDataForSelect[]>([])
  const [cityForMap, setCityForMap] = useState<IDataCity>(DEFAULT_CITY_MOSCOW)
  const [officeFullData, setOfficeFullData] = useState<OfficeDataWithMoreInfo | null>(null)

  const defaultSubmit = (valuesToRequest: Record<string, any>) => {
    sendRequest(`/v2/app/public/start`, { ...valuesToRequest, phoneValidate: !noVerify })
      .then(({ status, requestId }: Response) => {
        if (["pending", "repeated", "reject", "waitingDecision", "approve"].includes(status)) {
          setIDBValue("requestId", requestId)
          return Promise.resolve(status)
        }

        return Promise.reject(new Error(status))
      })
      .then((status) => {
        const { approve, reject, repeated } = events.applicationSend

        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(reject)
            return
          case "repeated":
            navigate("/error-repeat")
            pushToDataLayer(repeated)
            return
          case "error":
            throw Error(status)
          case "approve":
            pushToDataLayer(approve)
            break
          default:
            if (frame) {
              const formSentMessage = JSON.stringify({ action: "FORM_SENT" })
              window.parent.postMessage(formSentMessage, "*")
            }
            break
        }
        if (noVerify) {
          navigate("/success/")
        } else {
          navigate("/verify/", {
            state: { haveCourier: longForm, thanksPage },
          })
        }
      })
      .catch((e: Error) => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        console.error(e, "error is sendRequest")

        navigate("/error-technical/")
      })
      .finally(() => {
        pushToDataLayerStepSuccess(dataLayerName)
      })
  }

  const handleClick = (errors: object) => {
    // нужен для отправки ошибок при повторном нажатии button submit
    Object.entries(errors).forEach((fieldKey) => {
      const [fieldName, errorMessage] = fieldKey
      if (fieldName === "fio") {
        const сonvertErrorMessage = Object.entries(errorMessage.data)
          .map(([, surname]) => surname)
          .join(",")
        pushToDataLayerInvalidField(fieldName, сonvertErrorMessage, true)
      } else {
        pushToDataLayerInvalidField(fieldName, errorMessage, true)
      }
    })
    pushToDataLayerSendForm()
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      phone: "",
      fio: { data: { surname: null, name: null, patronymic: null } },
      agree: true,
      ...(hasBirth && { birthDate: "" }),
      ...(hasCityWithOffices && {
        localityAddress: {
          value: "",
          data: null,
          unrestricted_value: "",
        },
        deliveryOffice: "",
      }),
      ...(hasSelect && {
        inviteFriendFromWhom: "",
        customTextField: "",
      }),
    },
    validationSchema: FormSchema(hasBirth, hasSelect, hasCityWithOffices, selectItems),
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      const valuesToRequest = {
        agree: values.agree,
        surname: values.fio.data.surname,
        name: values.fio.data.name,
        patronymic: values.fio.data.patronymic,
        phone: values.phone.replace(/[- )(+]/g, ""),
        productName,
        additionalData: getAdditionalData(),
        sum: loanAmount,
        term,
        ...(hasBirth && {
          birthDate: formatBirthDate(values.birthDate),
        }),
        ...(hasCityWithOffices && {
          localityAddress: values.localityAddress,
          deliveryOffice: officesFromRequest.find(
            (office) => office.address === values.deliveryOffice
          )?.id,
        }),
        ...(hasSelect && {
          inviteFriendFromWhom:
            values.inviteFriendFromWhom === "Другое"
              ? values.customTextField
              : values.inviteFriendFromWhom,
        }),
      }

      setSubmitting(true)

      setIDBValue("phone", valuesToRequest.phone)

      if (!onSubmit) {
        defaultSubmit(valuesToRequest)
      } else {
        onSubmit(valuesToRequest)
      }
    },
  })

  useEffect(() => {
    if (dataLayerName) {
      pushToDataLayerLoadedStep(dataLayerName)
    }
  }, [dataLayerName])

  useEffect(() => {
    if (!hasCityWithOffices) return
    if (cityForMap?.city_kladr_id) {
      fetchOffices({
        kladrID: cityForMap.city_kladr_id,
        latitude: parseFloat(cityForMap.geo_lat),
        longitude: parseFloat(cityForMap.geo_lon),
        noFiltrationOffices: true,
      })
        .then((data) => {
          if (!data || !Array.isArray(data) || data.length < 1) return
          setOfficesFromRequest(data)
          if (
            formik.values.deliveryOffice &&
            !data.find((item) => item.address === formik.values.deliveryOffice)
          ) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleChangeSelectOffice("")
          }
          setOfficesForSelect(data?.map((item) => ({ value: item.address, label: item.address })))
        })
        .catch((e: Error) => {
          console.error(e.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityForMap?.city_kladr_id])

  useEffect(() => {
    if (!formik.values.localityAddress?.data?.city_kladr_id || !hasCityWithOffices) return
    if (formik.values.localityAddress?.data?.city_kladr_id !== cityForMap.city_kladr_id) {
      setCityForMap(formik.values.localityAddress.data)
    }
  }, [cityForMap.city_kladr_id, formik.values?.localityAddress?.data, hasCityWithOffices])

  useEffect(() => {
    if (!(officesFromRequest.length > 0)) return
    const officeFromSelect = officesFromRequest.find(
      (office) => office.address === formik.values.deliveryOffice
    )
    setOfficeFullData(officeFromSelect || null)
  }, [formik.values.deliveryOffice, officesFromRequest])

  const handleSelectChange = (event: { target: { value: string } }) => {
    formik.setFieldValue("inviteFriendFromWhom", event.target.value)
    setShowCustomInput(event.target.value === "Другое")
  }

  const handleOnPastePhoneField = (e: React.ClipboardEvent<Element>) => {
    let phone = e.clipboardData.getData("text").replace(/\D/g, "")
    if (!phone) return
    if (phone.length === 11) {
      phone = phone.slice(1)
    }
    formik.setFieldValue("phone", phone)
  }

  const handleChangeSelectOffice = (arg: string) => {
    formik.setFieldValue("deliveryOffice", arg, true)
    if (!formik.touched?.deliveryOffice) {
      formik.setFieldTouched("deliveryOffice", true)
    }
  }

  // TODO: вынести в функцию
  const FIO = formik.values.fio.data

  const resultPercentageGreenBar =
    (!formik.errors.fio
      ? (FIO.patronymic && FIO.patronymic?.length > 0
          ? ValidationPoints.FIRST_LETTER
          : ValidationPoints.INCORRECT_VALIDATION) +
        (FIO.name && FIO.name?.length > 0
          ? ValidationPoints.FIRST_LETTER
          : ValidationPoints.INCORRECT_VALIDATION) +
        (FIO.surname && FIO.surname?.length > 0
          ? ValidationPoints.FIRST_LETTER
          : ValidationPoints.INCORRECT_VALIDATION)
      : 0) +
    +(!formik.errors.phone
      ? ValidationPoints.CORRECT_VALIDATION
      : ValidationPoints.INCORRECT_VALIDATION)

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        {progressBar && (
          <ProgressBarGreen
            steps={1}
            widthAnimationBar={50}
            widthGreenBar={resultPercentageGreenBar}
          />
        )}
        {/* TODO: вынести fields отдельно. Для каждого fields в идеале сделать обертку. FormikForm должен быть оберткой, куда спускаются fields в качестве children */}
        <div
          onClick={() => handleClickForm}
          onKeyDown={() => handleClickForm}
          role="button"
          tabIndex={0}
        >
          <FioField
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            fioValue={formik.values.fio}
            errors={formik.errors.fio}
            touched={formik.touched.fio}
            name="fio"
            id="fio"
            type="fio"
            inputsColor={inputsColor}
            isGreenApprove={isGreenApprove}
            fioPlaceHolder={fioPlaceHolder}
            setFocusPhone={setFocusPhone}
          />
          <PhoneField
            focus={focusPhone}
            setFocus={setFocusPhone}
            onChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.errors.phone}
            touched={formik.touched.phone}
            isFioFieldValidate={Boolean(!formik.errors.fio)}
            name="phone"
            id="phone"
            inputsColor={inputsColor}
            isGreenApprove={isGreenApprove}
            onPaste={(e) => handleOnPastePhoneField(e)}
          />
          {choiceProduct && (
            <ChoiceProduct productName={productName} setProductName={setProductName} />
          )}
        </div>
        {hasBirth && (
          <BirthField
            onChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.birthDate}
            error={formik.errors.birthDate}
            touched={formik.touched.birthDate}
            name="birthDate"
            id="birthDate"
            inputsColor={inputsColor}
          />
        )}
        {hasCityWithOffices && formik.values.localityAddress && (
          <>
            <CityField
              setFieldValue={formik.setFieldValue}
              validateField={formik.validateField}
              touchField={formik.setFieldTouched}
              fieldValue={formik.values.localityAddress}
              error={formik.errors.localityAddress}
              touched={formik.touched.localityAddress}
              name="localityAddress"
              id="localityAddress"
              inputsColor={inputsColor}
            />
            <div className={clsx(styles.selectField, styles.selectFieldOffice)}>
              <Select
                fullWidth
                name="deliveryOffice"
                label="Адрес выбранного офиса"
                inputsColor="white"
                items={officesForSelect}
                value={formik.values.deliveryOffice || ""}
                onChange={(event: { target: { value: string } }) => {
                  handleChangeSelectOffice(event.target.value)
                }}
                error={formik.touched.deliveryOffice && formik.errors.deliveryOffice}
                MenuProps={{
                  MenuListProps: {
                    className: styles.listProps,
                  },
                  classes: {
                    paper: styles.listPaper,
                  },
                }}
              />
              {formik.errors.deliveryOffice && formik.touched.deliveryOffice && (
                <FormHelperText error className={styles.acceptmentError}>
                  {formik.errors.deliveryOffice}
                </FormHelperText>
              )}
            </div>
            <div className={styles.pickupContainer}>
              <PickupForForm
                officesFromRequest={officesFromRequest}
                initialScale={10}
                kladr={cityForMap.city_kladr_id}
                defLat={cityForMap.geo_lat}
                defLon={cityForMap.geo_lon}
                officeFullData={officeFullData}
                changeOfficeAtSelect={handleChangeSelectOffice}
              />
            </div>
          </>
        )}
        {hasSelect && (
          <div className={styles.selectField}>
            <Select
              fullWidth
              label={selectTitle}
              inputsColor="white"
              items={selectItems}
              value={formik.values.inviteFriendFromWhom}
              onChange={handleSelectChange}
              onBlur={formik.handleBlur}
              name="extraSelectName"
              error={formik.errors.agree}
            />
            {showCustomInput && (
              <div className={styles.selectFieldOther}>
                <MUITexteField
                  fullWidth
                  label="Введите свой вариант ответа"
                  value={formik.values.customTextField}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="customTextField"
                />
                <FormHelperText error className={styles.customTextFieldError}>
                  {formik.errors.customTextField && formik.touched.customTextField
                    ? formik.errors.customTextField
                    : ""}
                </FormHelperText>
              </div>
            )}
            {formik.submitCount > 0 && formik.errors.inviteFriendFromWhom && (
              <FormHelperText error className={styles.acceptmentError}>
                {formik.errors.inviteFriendFromWhom}
              </FormHelperText>
            )}
          </div>
        )}
        <AcceptmentField
          value={formik.values.agree}
          onChange={formik.handleChange}
          error={formik.errors.agree}
          name="agree"
          id="agree"
          touched={formik.touched.agree}
          variant={variant}
        />
        <Button
          classes={{ root: clsx(styles.btn, { [styles.btnWidth]: !fullWidth }, styles[variant]) }}
          type="submit"
          disabled={formik.isSubmitting}
          onClick={() => handleClick(formik.errors)}
          fullWidth={fullWidth}
        >
          {formBtnText}
        </Button>
      </form>
    </div>
  )
}
